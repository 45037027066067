import BaseRenderMixin from "@/components/base/BaseRenderMixin";
import {is_object, is_array} from "@/components/base/utils";

const BaseFilterComponent = () => import("@/components/base/filter/BaseFilterComponent"),
    BaseSearchComponent = () => import("@/components/base/filter/BaseSearchComponent"),
    BaseMultiBlogComponent = () => import("@/components/base/blog/BaseMultiBlogComponent"),
    BaseFormComponent = () => import("@/components/base/BaseFormComponent"),
    BaseHTMLContentComponent = () => import("@/components/base/BaseHTMLContentComponent")

import ComponentMap from "@/components/base/el/ComponentMap";

export default {
    name: "BasePopupComponent",
    mixins: [BaseRenderMixin],
    props: {
        component_data: Object,
    },
    data() {
        return {
            intervalid: ''
        }
    },
    created() {
        if (this.component_data.attr["popup-id"]) {
            this.$store.state.activePopups.push(this)
        } else {
            this.component_data.attr["popup-id"] = this._uid
            this.component_data.attr["name"] = "Popup " + this._uid
            this.component_data.attr["active"] = true
            this.component_data.attr["auto-open"] = false
            this.component_data.attr["time-open"] = 3

            this.$store.state.activePopups.push(this)
        }
    },
    mounted() {
        if (JSON.parse(this.component_data.attr["auto-open"]) && !this.$store.state.edit_mode) {
            this.intervalid = setInterval(() => {
                if (window.$ && window.$(`*[popup-id='${this.component_data.attr["popup-id"]}']`).modal) {
                    clearInterval(this.intervalid)
                    setTimeout(() => {
                        window.$(`*[popup-id='${this.component_data.attr["popup-id"]}']`).modal('toggle')
                    }, parseInt(this.component_data.attr["auto-open"]) * 1000);
                }
            }, 300)
        }
    },
    methods: {
        is_special_div(element_data) {
            if (element_data.tag !== 'div') return false

            if (element_data.child && is_array(element_data.child)) {
                let text_nodes = element_data.child.filter(c => c.node === 'text')
                if (text_nodes.length > 0) return true
            }

            if (element_data.attr &&
                element_data.attr.style &&
                element_data.attr.style.backgroundImage) return true

            return false
        },
        is_special_section(element_data) {
            if (element_data.tag !== 'section') return false

            if (element_data.child && is_array(element_data.child)) {
                let text_nodes = element_data.child.filter(c => c.node === 'text')
                if (text_nodes.length > 0) return true
            }

            if (element_data.attr &&
                element_data.attr.style &&
                element_data.attr.style.backgroundImage) return true

            return false
        },
        make_element_tree(createElement, element_data, options = {}, context = null) {
            let data = {}
            let el, props, attrs, style, children

            [el, props] = this.get_el_props(element_data, context)
            attrs = this.get_attrs(element_data, context)
            children = this.get_children(element_data, createElement)
            style = this.get_style(element_data, context)

            if (props) data.props = props
            if (attrs) data.attrs = attrs
            if (style) data.style = style

            for (let key in options) data[key] = options[key]

            return createElement(
                el,
                data,
                children
            )
        },
        get_el_props(element_data) {
            let el = element_data.tag
            let props = null

            if (element_data.attr) {
                if ('deepsel-html-content' in element_data.attr) {
                    el = BaseHTMLContentComponent
                    props = {component_data: element_data}
                    return [el, props]
                }
                if ('deepsel-multi-blog' in element_data.attr) {

                    el = BaseMultiBlogComponent
                    props = {
                        component_data: element_data,
                        blogs: this.$store.state.common_data.blogs
                    }
                    return [el, props]
                }
                if ('deepsel-filter' in element_data.attr) {
                    el = BaseFilterComponent
                    props = {component_data: element_data}
                    return [el, props]
                }
                if ('deepsel-search' in element_data.attr ||
                    (element_data.tag === 'input' && element_data.attr.type === 'search')) {
                    el = BaseSearchComponent
                    props = {component_data: element_data}
                    return [el, props]
                }

            }

            // if (this.$store.state.edit_mode) {
            if (Object.keys(ComponentMap).includes(element_data.tag)) {

                el = ComponentMap[element_data.tag]
                props = {component_data: element_data}

            } else if (this.is_special_div(element_data)) {

                el = ComponentMap.special_div
                props = {component_data: element_data}

            } else if (this.is_special_section(element_data)) {

                el = ComponentMap.special_section
                props = {component_data: element_data}

            } else if (element_data.tag === 'form') {
                el = BaseFormComponent
                props = {component_data: element_data}
            }
            // }

            return [el, props]
        },
        get_attrs(element_data) {
            if (element_data.attr) {
                let attrs = {}
                for (let key in element_data.attr) {
                    if (key === 'style' && is_object(element_data.attr[key])) continue
                    let value = element_data.attr[key];
                    attrs[key] = Array.isArray(value) ? value.join(" ") : value
                }
                return attrs
            } else return null
        },
        get_style(element_data) {
            if (element_data.attr && is_object(element_data.attr.style)) {
                let origin = this.$store.state.origin;
                let style = JSON.parse(JSON.stringify(element_data.attr.style))

                if (style.backgroundImage) {
                    if (
                        !style.backgroundImage.includes("https://") &&
                        !style.backgroundImage.includes("http://")
                    ) {
                        let background_img_path = style.backgroundImage
                            .replace('url(', '')
                            .replace(')', '')
                            .replace(/'/g, '')

                        // background_img_path = background_img_path.replace(/\\/g, " ")
                        if (background_img_path.slice(0, 1) !== "/") {
                            background_img_path = "/" + background_img_path;
                        }

                        style.backgroundImage = `url(${origin + background_img_path})`
                    }
                }
                return style
            } else return null
        },
        get_children(element_data, createElement) {
            let children = []
            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {
                        children.push(this.make_element_tree(createElement, item));
                    } else if (item.node === "text") children.push(item.text)
                }
            }

            return children
        },
        change_attr(obj) {
            this.component_data.attr = JSON.parse(JSON.stringify(obj))
            console.log(JSON.stringify(this.component_data.attr));
        }
    },
    computed: {
        name: {
            get() {
                return this.component_data.attr.name
            },
            set(val) {
                console.log(val, this.component_data.attr);
                this.change_name(val)
            }
        },
    }
}